import { Helmet } from 'react-helmet';
import Nav from '../components/Nav';
import '../App.scss';
import Hero from '../components/Hero';
import heroImage from '../assets/images/homepage-hero.jpg'
import life from '../assets/images/life-at-arizona.jpg'
import franke from '../assets/images/meet-franke-honors.jpg'
import exclusive from '../assets/images/exclusive-prviliges.jpg'
import LearnMore from '../components/LearnMore';
import Squares from '../components/Squares';
import Ranking from '../components/Ranking';
import Gallery from '../components/Gallery';
import Footer from '../components/Footer';

const Home = () => {

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Flinn Home | University of Arizona</title>
        <meta name="description" content="Create the future you’re dreaming of as a member of the University of Arizona W.A. Franke Honors College." />
      </Helmet>
      <Nav/>
      <Hero home={true} heroImage={heroImage} overlay={true}/>
      <Squares home={true}/>
      <LearnMore home={true} cta={true}/>
      <Ranking home={true}/>
      <Gallery home={true} image1={franke} image2={exclusive} image3={life}/>
      <Footer/>
    </div>
  );
}

export default Home;
