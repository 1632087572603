import React from "react";
import simon from '../assets/images/simon.jpg';
import north from '../assets/images/north.jpg';
import gia from '../assets/images/gia.jpg';
import elizabeth from '../assets/images/elizabeth.jpg';

const Bios = () => {
    return (
        <div class="container">
            <div>
                <p class="p-24-LR text-4xl font-bold arizona-blue max-sm:text-3xl">Your Flinn Community</p>

                <div class="p-24-LR flex border-bottom-bloom pt-14 pb-14 max-md:flex-wrap">
                    <div class="pr-6 max-w-[282px]">
                        <img class="max-w-[225px]" src={simon}></img>
                    </div>
                    <div>
                        <p class="font-bold text-xl arizona blue max-md:pt-2">Simon</p>
                        <p class="arizona-blue text-lg font-bold">Hometown: Tucson, AZ </p>
                        <p class="arizona-blue text-lg font-bold">Majors: Chemical Engineering and Political Science</p>
                        <br/>
                        <p class="azurite font-bold">Why did you choose Arizona?</p>
                        <p>I’ve always wanted to go to Arizona, but what really pushed me to go was when I met some of the faculty at the Flinn event. Every one of the professors I met was very understanding, open to talking, and were really interested in being the best teacher they could be.</p>
                        <br/>
                        <p class="azurite font-bold">What are your career goals? </p>
                        <p>I hope to work for NASA in the future. Arizona offered the ASTEROIDS program, which is affiliated with NASA, as an option to get involved in paid research. So that connection is really helping out my career goal.</p>
                        <br/>
                        <p class="azurite font-bold">What’s your favorite thing about Tucson?</p>
                        <p>The food is fantastic. But mostly, Tucson feels like home. It’s very comfortable. Even though it’s decently big, it seems as though everybody knows everybody through one person or the other.</p>
                    </div>
                </div>

                <div class="p-24-LR flex border-bottom-bloom pt-14 pb-14 max-md:flex-wrap">
                    <div class="pr-6 max-w-[282px]">
                        <img class="max-w-[225px]" src={elizabeth}></img>
                    </div>
                    <div>
                        <p class="font-bold text-xl arizona blue max-md:pt-2">Elizabeth</p>
                        <p class="arizona-blue text-lg font-bold">Hometown: Gilbert, AZ</p>
                        <p class="arizona-blue text-lg font-bold">Majors: Biomedical Engineering</p>
                        <br/>
                        <p class="azurite font-bold">Why did you choose Arizona?</p>
                        <p>It has robust research opportunities for its undergraduate students. Also, the people here are very friendly, and I love the environment.</p>
                        <br/>
                        <p class="azurite font-bold">What are your career goals?</p>
                        <p>I want to be a medical researcher, and maybe combine that with becoming a practicing doctor.</p>
                        <br/>
                        <p class="azurite font-bold">What’s your favorite thing about Tucson? </p>
                        <p>The old-town feel of the city is both new to me and very pleasant. I’ve seen a lot of wonderful small businesses and local shops in the area.</p>
                    </div>
                </div>
                <div class="p-24-LR flex border-bottom-bloom pt-14 pb-14 max-md:flex-wrap">
                    <div class="pr-6 max-w-[282px]">
                        <img class="max-w-[225px]" src={north}></img>
                    </div>
                    <div>
                        <p class="font-bold text-xl arizona blue max-md:pt-2">North</p>
                        <p class="arizona-blue text-lg font-bold">Hometown: Tucson, AZ </p>
                        <p class="arizona-blue text-lg font-bold">Majors: English and Pre-Nursing</p>
                        <br/>
                        <p class="azurite font-bold">Why did you choose Arizona? </p>
                        <p>The University of Arizona has an excellent nursing program, and it’s also close to home (and my four guinea pigs).</p>
                        <br/>
                        <p class="azurite font-bold">What are your career goals?</p>
                        <p>After I am hopefully accepted into nursing school, I plan on getting a graduate degree as a Nurse Practitioner, likely specializing in psychiatry. If I can, I’d like to become an English professor in “retirement.”</p>
                        <br/>
                        <p class="azurite font-bold">What’s your favorite thing about Tucson?</p>
                        <p>I get cold easily, so honestly the climate. I also like that it’s a smaller city than Phoenix. And cacti! I love cacti.</p>
                    </div>
                </div>
                <div class="p-24-LR flex pt-14 pb-14 max-md:flex-wrap">
                    <div class="pr-6 max-w-[282px]">
                        <img class="max-w-[225px]" src={gia}></img>
                    </div>
                    <div>
                        <p class="font-bold text-xl arizona blue max-md:pt-2">Gia</p>
                        <p class="arizona-blue text-lg font-bold">Hometown: Phoenix, AZ</p>
                        <p class="arizona-blue text-lg font-bold">Majors: Pharmaceutical Sciences</p>
                        <br/>
                        <p class="azurite font-bold">Why did you choose Arizona? </p>
                        <p>I really liked the campus when we came for our Flinn visit! The opportunities available here are endless.</p>
                        <br/>
                        <p class="azurite font-bold">What are your career goals?</p>
                        <p>My long-term career goals are to go to medical school and become a physician. I’m not sure what specialty yet, as of now everything sounds amazing!</p>
                        <br/>
                        <p class="azurite font-bold">What’s your favorite thing about Tucson? </p>
                        <p>Exploring the food in Tucson has been my favorite thing! There are so many cuisines and places to try, my friends and I try to go somewhere new every week.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bios;