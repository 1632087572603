const Details = ({eventDetails, faq}) => {
    const handleClick = (e) => {        
        const currentButton = e.target.classList

        if (currentButton.contains('expanded')) {
            currentButton.remove('expanded')
        } else {
            currentButton.add('expanded')
        }

        const panelClass = e.target.nextElementSibling.classList;

        if (panelClass.contains('activeAccordian')) {
            panelClass.remove('activeAccordian')
        } else {
            panelClass.add('activeAccordian')
        }
    }
    
    return (
        <div class="w-full cool-gray-bg">
            {
                eventDetails &&    <div class="container p-24-LR pt-20" id="eventDetailsSection">
                <h1 class="arizona-blue text-5xl font-extrabold pb-8">EVENT DETAILS</h1>
                <p class="arizona-blue font-bold">February 24, 2025</p>
                <p class="arizona-blue font-bold">University of Arizona, Tucson, Arizona</p>
                <p class="pt-4">This is a special event designed for Flinn Finalists and their families. You may bring up to two parent(s)/guardian(s) to participate in the activities.</p> 
                <p class="pt-4"> Please <a target="_blank" href="https://slate.admissions.arizona.edu/register/?id=d5c9fcd0-ae56-4a05-80f4-588ceae34e6c" class="brand inline">RSVP</a> by February 17, 2025. If you have any questions, contact Brittany Tellez at <a class="brand" href="mailto:bfraser1@arizona.edu">bfraser1@arizona.edu</a></p>
                <div class="text-white mt-4 pt-3 pb-3 pl-20 pr-20 font-extrabold text-center arizona-red-bg w-60 rounded-3xl max-sm:pl-10 max-sm:pr-10 ">
                    <a target="_blank" href="https://slate.admissions.arizona.edu/register/?id=d5c9fcd0-ae56-4a05-80f4-588ceae34e6c">RSVP</a>
                </div>
                <p class="pt-4">Can’t make it to the event but still want to tour the Honors Village? Contact us at <a class="brand" href="mailto:honors@arizona.edu">honors@arizona.edu</a> and we will gladly arrange a customized visit experience.</p>
                <h2 class="mt-10 arizona-blue text-3xl font-bold">Agenda & Events</h2>
                <p class="pt-4 pb-4">Additional details will be shared soon, so please check back regularly for the latest updates.</p>
                <div class="accordion">

                        <div class="md:flex">
                            <div class="flex ">
                            <div class="redBar"></div>
                                <div class="schedule">
                                    <p class="brand">9:30 a.m.</p>
                                    <p>Check-in begins at Honors Village</p>
                                </div>
                            </div>
                            <div class="flex ">
                            <div class="redBar"></div>
                                <div class="schedule">
                                    <p class="brand">10 a.m.</p> 
																		<p>W.A. Franke Honors College welcome</p>
																</div>
														</div>
                            <div class="flex ">
                            <div class="redBar"></div>
                                <div class="schedule">
                                    <p class="brand">10:15 a.m.</p> 
																		<p>W.A. Franke Honors College information session </p>
																</div>
														</div>
												</div>
												<div class="md:flex md:mt-6">
                            <div class="flex ">
                            <div class="redBar"></div>
                                <div class="schedule">
                                    <p class="brand">10:45 a.m.</p> 
																		<p>Honors Village Tour</p>
																</div>
														</div>
                            <div class="flex ">
                            <div class="redBar"></div>
                                <div class="schedule">
                                    <p class="brand">11:45 a.m.</p>
																		<p>Luncheon at Student Union Memorial Center</p>
																</div>
														</div>
                            <div class="flex ">
                            <div class="redBar"></div>
                                <div class="schedule">
                                    <p class="brand">1:30 p.m.</p> 
																		<p>Meet and greet with current Flinns and staff at Old Main</p>
																</div>
														</div>
												</div>
												<div class="md:flex md:mt-6">
                            <div class="flex ">
                            <div class="redBar"></div>
                                <div class="schedule">
                                    <p class="brand">2 p.m.</p> 
																		<p>College Experience #1</p>
																</div>
														</div>
                            <div class="flex ">
                            <div class="redBar"></div>
                                <div class="schedule">
                                    <p class="brand">3:30 p.m.</p>
																		<p>College Experience #2</p>
																</div>
														</div>
                            <div class="flex ">
                            <div class="redBar"></div>
                                <div class="schedule">
                                    <p class="brand">5-6 p.m.</p>
																		<p>Closing session</p>
																</div>
														</div>
                        </div>
                    </div>
                </div>

            }
            {
                faq && 
                <div class="container p-24-LR pt-20">
                <h1 class="arizona-blue text-5xl font-extrabold pb-8">FAQS</h1>

                <div class="accordion">
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">What is the Flinn Finalist event?</button>
                    <div class="accordion-content">
                       <p>Hosted by the University of Arizona W.A. Franke Honors College, this special event is where Flinn Finalists from across the state come together to learn about our R1 research university, network with their peers and mentors, and preview what it’s like to be an Arizona Wildcat. The event is carefully designed to help you fully experience everything our university and W.A. Franke Honors College has to offer.</p>
                    </div>
                </div>
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">Will I get a tour of the Honors Village?</button>
                    <div class="accordion-content">
                       <p>Yes! We can’t wait to show you around our state-of-the-art facility. The Honors Village features contemporary living spaces, as well as on-site classrooms, study spaces, and dining – all exclusively designed for honors students.</p>
                    </div>
                </div>
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">Who can I bring with me to the event?</button>
                    <div class="accordion-content">
                       <p>Flinn Finalists may bring up to two parents and/or guardians as guests for the event. Your guest(s) are encouraged to participate in all activities with you.</p>
                    </div>
                </div>
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">What should I bring?</button>
                    <div class="accordion-content">
                       <p>Dress comfortably to tour campus and the Honors Village. You should plan to bring any other essentials you will need during the day. Lunch will be provided.</p>
                    </div>
                </div>
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">I can’t afford the event. What should I do?</button>
                    <div class="accordion-content">
                       <p>The Flinn Scholar Finalist Visit is a free event. If transportation to Tucson, AZ creates a burden, please reach out to Brittany Tellez at <a class="brand" href="mailto:bfraser1@arizona.edu">bfraser1@arizona.edu</a> to assist with transportation to the event.</p>
                    </div>
                </div>
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">What if I'm unable to attend the full event?</button>
                    <div class="accordion-content">
                       <p>If you can only attend a portion of the event, please contact Brittany Tellez at <a class="brand" href="mailto:bfraser1@arizona.edu">bfraser1@arizona.edu</a> to coordinate your arrangements.</p>
                        <br/>
                        <p>If you are unable to attend the entire event, we would still love to meet you and arrange a visit experience. Please contact us at <a class="brand" href="mailto:honors@arizona.edu."></a>honors@arizona.edu.</p>
                    </div>
                </div>
                <div class="accordion-item">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">Do I need to apply to the W.A. Franke Honors College?</button>
                    <div class="accordion-content">
                       <p>In recognition of your status as a Flinn Finalist, you will automatically be admitted into the W.A. Franke Honors College. All you have to do is <a class="brand" target="_blank" href="https://slate.admissions.arizona.edu/apply/"></a>submit your application to the University of Arizona. Once you have been admitted to the university, you can officially become an Arizona Wildcat, you can finish enrolling in your <a target="_blank" class="brand" href="https://nextsteps.arizona.edu/nextsteps">Next Steps Center</a>.</p>
                    </div>
                </div>
                <div class="accordion-item accordion-bottom">
                    <button onClick={handleClick} class="accordion-header azurite font-bold">Who should I contact if I still have questions?</button>
                    <div class="accordion-content">
                       <p>Please contact Brittany Tellez at <a class="brand" href="mailto:bfraser1@arizona.edu">bfraser1@arizona.edu</a> with any questions. If you’re interested in learning more about the University of Arizona Honors College, visit us at <a class="brand" target="_blank" href="honors.arizona.edu.">honors.arizona.edu.</a></p>
                    </div>
                </div>
                </div>
                </div>
            }
     
        </div>
    );
}
export default Details;