import React from "react";
import Square from '../assets/images/abstract-square-group.svg'
import Square1 from '../assets/images/abstract-square-1.svg'
import Square2 from '../assets/images/abstract-square-2.svg'
import Square3 from '../assets/images/abstract-square-3.svg'

const Squares = ({home}) => {
    
    return (
        <div class="flex max-[403px]:flex-wrap max-sm:justify-center container pt-20 pb-8 p-24-LR">
            <div class>
                <img class="mb-2" src={Square} alt="abstract image of 4 squares" />
            </div>
            {
                home && 
                <div class="min-[393px]:ml-4 flex justify-start mb-2">
                <img class="" src={Square1} alt="abstract image of 4 squares" />
                <img class="pl-2 pr-2" src={Square2} alt="abstract image of 4 squares" />
                <img class="" src={Square3} alt="abstract image of 4 squares" />

                </div>
            }
        </div>
    );
};

export default Squares;