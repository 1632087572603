import React from "react";
import Square1 from '../assets/images/abstract-square-1.svg'
import Square2 from '../assets/images/abstract-square-2.svg'
import Square3 from '../assets/images/abstract-square-3.svg'

const Ranking = ({home, scholars, info}) => {
    return (
        
        <div class="container pt-20 pb-20">
            {
                home && 
                <>
                {/* <div class="pl-24 pr-24 flex flex-wrap text-center justify-between max-lg:justify-center"> */}
                <div class="pl-24 pr-24 md:flex text-center justify-between max-lg:justify-center">

                <div class="max-lg:pt-3 max-w-[281px] ml-auto mr-auto">
                    <img class="ml-auto mr-auto" src={Square1}></img>
                    <p class="azurite text-7xl max-sm:text-5xl font-extrabold top-rank">TOP 25</p>
                    <p class="arizona-blue text-4xl max-sm:text-xl font-bold" >Flagship Universities</p>
                    <p class="arizona-blue">—— U.S. News & World Report</p>
                </div>
                <div class="max-lg:pt-3 max-w-[281px] ml-auto mr-auto">
                <img class="ml-auto mr-auto" src={Square2}></img>
                    <p class="azurite text-7xl font-extrabold max-sm:text-5xl top-rank">TOP 20</p>
                    <p class="arizona-blue text-4xl max-sm:text-xl font-bold">Public Research</p>
                    <p class="arizona-blue">—— National Science Foundation</p>
                </div>
                <div class="max-lg:pt-3 max-w-[281px] ml-auto mr-auto">
                <img class="ml-auto mr-auto" src={Square3}></img>
                    <p class="azurite text-7xl max-sm:text-5xl font-extrabold top-rank">TOP 25</p>
                    <p class="arizona-blue text-4xl max-sm:text-xl font-bold">U.S. Publics</p>
                    <p class="arizona-blue">—— Times Higher Education</p>
                </div>
            </div>
                </>
            }

            {
                scholars && 
                <>
                <div class="flex flex-wrap text-xl justify-between p-24-LR">
                <div class="lg:max-w-[350px] pl-10 border-bloom">
                    <p class="azurite  ">There are <strong>45,025</strong> undergraduates at the U of A. Of those, <strong>4,294</strong> are Franke Honors Wildcats.</p>
                </div>
                <div class="lg:max-w-[350px] pl-10 mt-5 lg:mt-2 border-bloom">
                <p class="azurite  ">Among the state’s public universities, Arizona is <strong>#1</strong> for research and development.</p>
                </div>
                <div class="lg:max-w-[350px] pl-10 mt-5 lg:mt-2 border-bloom">   
                <p class="azurite  ">Arizona is the state’s only university that has <strong>two</strong> public medical schools.</p>
                </div>
            </div>
                </>
            }

            {
                info && 
                <div class="container">
                    <div class="info-footer ml-auto mr-auto">
                    <p>
                    “Being a Franke Flinn Scholar has helped me gain access to faculty mentors who I otherwise would not have known, such as my mentor who is a professor at the law school. I also appreciate being a part of the Flinn community because it has made it easier to make friends.”
                    </p>
                    <p>—— Catherine, Franke Flinn Scholar, Sociology and Neuroscience & Cognitive Science Major</p>
                    </div>
                </div>
            }
         
        </div>
    );
}

export default Ranking;