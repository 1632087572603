import { Helmet } from 'react-helmet';
import Nav from '../components/Nav';
import '../App.scss';
import Hero from '../components/Hero';
import heroImage from '../assets/images/hero.jpg'
import LearnMore from '../components/LearnMore';
import Squares from '../components/Squares';
import Gallery from '../components/Gallery';
import Footer from '../components/Footer';
import Details from '../components/Details';
import uni from '../assets/images/university-blvd.jpg';
import fourth from '../assets/images/downtown.jpg';
import lemmon from '../assets/images/mt-lemmon.jpg';
import community from '../assets/images/exclusive-prviliges.jpg'
import exclusive from '../assets/images/exclusive-benefits.jpg'
import honors from '../assets/images/honors-village.jpg'

const Finalist = () => {

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Flinn Home | University of Arizona</title>
        <meta name="description" content="Create the future you’re dreaming of as a member of the University of Arizona W.A. Franke Honors College." />
      </Helmet>
      <Nav/>
      <Hero finalist={true} heroImage={heroImage} overlay={true}/>
      <Squares/>
      <LearnMore finalist={true}/>
      <Details eventDetails={true}/>
      <Gallery finalist={true} image1={uni} image2={fourth} image3={lemmon}/>
      <Details faq={true}/>
      <Gallery finalistHonors={true} image1={exclusive} image2={community} image3={honors}/>
      <Footer rsvp={true}/>


    </div>
  );
}

export default Finalist;
