import {Helmet} from 'react-helmet';
import React from "react";
import Nav from "../components/Nav";
import LearnMore from "../components/LearnMore";
import Squares from "../components/Squares";
import Hero from "../components/Hero";
import heroImage from '../assets/images/meet-current-flins-hero.jpg'
import Bios from "../components/Bios";
import Footer from "../components/Footer";

const Profiles = () => {
    return (
    <div>
       <Helmet>
        <meta charSet="utf-8" />
        <title>Meet Current Flinns | University of Arizona</title>
        <meta name="description" content="Study alongside top students and faculty at the University of Arizona W.A. Franke Honors College." />
      </Helmet>
    <Nav/>
      <Hero heroImage={heroImage}/>
      <Squares/>
      <LearnMore profiles={true} secondaryHeading="JOIN THE BEST AND BRIGHTEST" bodyText="Flinns from all over the state choose the University of Arizona for good reason. Get to know a few of our Flinn Wildcats – their goals, motivations, and everything they love about Tucson."/>
        <Bios/>
        <Footer/>
    </div>
    );
}

export default Profiles;