import React from "react";

const Button = ({text}) => {

    return(
        <div class="margin-auto sm:ml-auto sm:mr-auto lg:ml-24 mt-10 text-white pt-3 pb-3 pl-20 pr-20 font-extrabold text-center arizona-red-bg sm:w-72 rounded-3xl max-sm:pl-10 max-sm:pr-10 ">
              <a href="/scholars">{text}</a>
        </div>

    );
}

export default Button;