import React from "react";
import logo from '../assets/images/Franke-Honors-Logo.svg'

const Footer = ({rsvp}) => {
    return (
        <div>
            {
                rsvp && 
                <div class="arizona-blue-bg pt-20">
                    <div class="container">
                    <div class="lg:max-w-[340px] p-24-LR">
                    <h1 class="text-5xl text-white font-extrabold ">WE'LL SEE YOU SOON</h1>
                    </div>
                    <div class="p-24-LR pt-4 lg:flex">
                        <p class="text-white">Our laid-back college hometown offers something for everyone. If you’re visiting from out of town, there are plenty of sights to see while you’re here.</p>
                        <div class="max-lg:mt-4 margin-auto sm:ml-auto sm:mr-auto lg:ml-24 text-white pt-3 pb-3 pl-20 pr-20 font-extrabold text-center arizona-red-bg sm:w-72 rounded-3xl max-sm:pl-10 max-sm:pr-10 ">
                            <a target="_blank" href="https://slate.admissions.arizona.edu/register/?id=d5c9fcd0-ae56-4a05-80f4-588ceae34e6c">RSVP</a>
                        </div>
                    </div>
                    </div>
                </div>
            }
                
            <div class="text-center arizona-blue-bg align-middle pt-20 pb-20  p-24-LR">
            <div>
            <a href="http://www.arizona.edu" target="_blank" rel="noopener"><img class="ml-auto mr-auto pb-8"  src={logo} alt="The University of Arizona logo"/></a>
                <a className="text-white pt-8 underline click" href="https://www.arizona.edu/information-security-privacy?_gl=1*kafrvl*_ga*OTQ3OTI5NjAxLjE2NjMwMjAzOTA.*_ga_7PV3540XS3*MTcwMDUyMzI3NC4xNjkuMS4xNzAwNTIzOTM4LjYwLjAuMA..">University Information Security and Privacy</a>
                <p className="text-white pt-2">© 2025 Arizona Board of Regents on behalf of The University of Arizona</p>

            </div>
        </div>
        </div>
    
    );
}

export default Footer