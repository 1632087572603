import { Helmet } from 'react-helmet';
import Nav from '../components/Nav';
import '../App.scss';
import Hero from '../components/Hero';
import heroImage from '../assets/images/flinn-scholars-hero.jpg';
import forge from '../assets/images/forge-your-path.jpg';
import support from '../assets/images/support.jpg'
import legacy from '../assets/images/your-legacy-starts.jpg'
import LearnMore from '../components/LearnMore';
import Squares from '../components/Squares';
import Ranking from '../components/Ranking';
import Footer from '../components/Footer';
import Info from '../components/Info';

const Scholars = () => {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Flinn Scholars | University of Arizona</title>
        <meta name="description" content="The University of Arizona W.A. Franke Honors College is home to some of the best and brightest minds from around the world. Your legacy starts today." />
      </Helmet>
      <Nav/>
      <Hero heroImage={heroImage}/>
      <Squares/>
      <LearnMore scholars={true} mainHeading="YOUR LEGACY STARTS TODAY" learnMoreImage={legacy} bodyText={"The University of Arizona has a long history of Flinn Scholars, and we want you to be next."} secondaryBodyText={"As a Flinn Finalist and exceptional all-around student, you will feel right at home at the University of Arizona W.A. Franke Honors College. Surrounded by other talented, passionate students, you’ll be at the table with some of the best and brightest minds from"}/>
      <Ranking scholars={true}/>
      <Hero heading={"Forge Your Own Degree Path"} text={"We invite you to forge your own degree path that aligns with your dynamic talents and interests as a valued member of the Franke Honors College. Franke Flinn Wildcats enjoy all of the advantages the Franke Honors College has to offer and then some — which makes them some of the highest-performing and highest-achieving students on campus. Be mentored by top faculty, participate in projects backed by a top-20 research institution, and find a home in our tight-knit Franke Flinn community."} bannerImage={forge}/>
      <LearnMore scholars={true} secondaryHeading="Support Where It Counts" learnMoreImage={support} bodyText={"Mentorship is one of the keystones of the Franke Flinn Scholars program. During your first year at Arizona, you will be matched with a faculty mentor who will offer academic guidance, help you navigate majors, provide career advice, and connect you with academic and research opportunities."} secondaryBodyText={"Your Franke Flinn mentor will meet with you throughout the year, helping to shape your experience at Arizona. They will be an excellent resource when you are looking for suggestions on effective research methods, coursework and co-curricular activities."} />
      <Info/>
      <Footer/>
    </div>
  );
}

export default Scholars;
