import React from "react";
import "../pages/Scholars";

const Hero = ({heroImage, overlay, text, bannerImage, home, heading, finalist}) => {
    return (
        <section>
              {
                !text && home &&
                <div class="hero lg:h-[530px] h-[350px] bg-cover bg-no-repeat z-0" style={{backgroundImage: `url(${heroImage})`}}>
                       { 
                           overlay && 
                           <div class="container h-full">
                               <div class="bg-gray-200 w-full md:max-w-[610px] h-full bg-opacity-90 table"> 
                                   <div class="max-w-[530px] table-cell align-middle pl-7 pr-5">
                                       <p class="arizona-blue text-3xl">FLINN SCHOLARS</p>
                                       <p class="arizona-blue text-6xl mt-5 font-extrabold max-md:text-4xl">CREATE THE FUTURE YOU'RE DREAMING OF</p>
                                   </div>
                               </div>
                           </div>
                       }
                </div>
            }

            {
                !text && !home && !finalist &&
                <div class="pad24LR bg-cover bg-no-repeat z-0" style={{backgroundImage: `url(${heroImage})`, padding: '13%'}}>
            
                </div>
            }

            {
                text &&
                <div class="flex flex-wrap">
                    <div class="basis-1/2 bg-cover bg-no-repeat" style={{backgroundImage: `url(${bannerImage})`}}>
                    </div>
                    <div class="p-24-LR basis-1/2 max-lg:basis-full cool-gray-bg pl-24 pb-20 pt-20 pr-32 max-lg:pl-2 max-lg:pr-2">
                        <p class="arizona-blue text-4xl font-bold lg:w-1/2">{heading}</p>
                        <br/>
                        <p>{text}</p>
                    </div>
                </div>
            }


{
                finalist &&
                <div class="hero lg:h-[530px] h-[350px] bg-cover bg-no-repeat z-0" style={{backgroundImage: `url(${heroImage})`}}>
                { 
                    overlay && 
                    <div class="container h-full">
                        <div class="bg-gray-200 w-full md:max-w-[610px] h-full bg-opacity-90 table"> 
                            <div class="max-w-[530px] table-cell align-middle pl-7 pr-5">
                                <p class="arizona-blue text-xl sm:text-3xl">CONGRATULATIONS</p>
                                <p class="rule arizona-blue sm:text-6xl mt-4 font-extrabold max-md:text-4xl pb-4">FLINN FINALIST</p>
                                <p class="arizona-blue sm:text-3xl mt-4 azurite">WE CAN'T WAIT TO MEET YOU</p>
                                <p class="arizona-blue sm:text-3xl font-extrabold azurite">FEBRUARY 24, 2025</p>

                            </div>
                        </div>
                    </div>
                }
         </div>
            }
        </section>
    )
}

export default Hero;