import React from "react";
import say from '../assets/images/wildcats-say.svg'

const Info = ({life}) => {
    return (
        
        
        <>
        {
            !life &&  <div class="wrapper relative pt-10">

            <div class="max-w-[78px] absolute left-[47%] flex justify-center top-0 w-full ml-auto mr-auto">
               <img src={say}></img>
            </div>
            
                 <div class="p-24-LR max-w-[1200px] ml-auto mr-auto pt-20 pb-20 mb-20 info-border">
            
            
                 <div class="azurite info-footer ml-auto mr-auto">
                
                 <p class="text-xl italic">
                 “Being a Franke Flinn Scholar has helped me gain access to faculty mentors who I otherwise would not have known, such as my mentor who is a professor at the law school. I also appreciate being a part of the Flinn community because it has made it easier to make friends.”
                 </p>
                 <br/>
                 <p class=" font-bold">—— Catherine, Franke Flinn Scholar, Sociology and Neuroscience & Cognitive Science Major</p>
                 </div>
                 </div>
            
            </div>
        }

        {
            life &&
            <>
            <div class="container p-24-LR max-md:pt-10">
                <p class="arizona-blue pb-12 max-w-[486px] font-bold text-4xl pr-3 max-lg:text-3xl">
                Why Flinns Love Tucson — In Their Own Words
                </p>
            </div>

            <div class="wrapper relative pt-10">

            <div class="max-w-[78px] absolute left-[47%] flex justify-center top-0 w-full ml-auto mr-auto">
               <img src={say}></img>
            </div>
            
                 <div class="max-w-[1200px] ml-auto mr-auto pt-20 pb-20 mb-20 info-border">
            
            
                 <div class="azurite ml-auto mr-auto flex justify-between max-lg:flex-wrap max-md:justify-center p-24-LR">
                    <div class="lg:max-w-[360px] max-lg:pt-12">
                        <p class="text-xl italic">
                        “Tucson has great weather and beautiful trails to hike. It is also a great size, and nothing is too far away to be able to enjoy. It’s really easy and fun to explore the Tucson area throughout the school year.”                        </p>
                        <br/>
                        <p class=" font-bold">—— Catherine, Franke Flinn Scholar, Sociology and Neuroscience & Cognitive Science Major</p>
                    </div>
                    <div class="lg:pl-4 lg:pr-4 lg:max-w-[360px] max-lg:pt-12">
                        <p class="text-xl italic">
                        “I have enjoyed exploring the local restaurants and bakeries around Tucson. There are so many hidden gems off campus.”                        </p>
                        <br/>
                        <p class=" font-bold">—— Taylor, Physiology and Medical Sciences Major</p>
                    </div>
                    <div class="lg:max-w-[360px]  max-lg:pt-12">
                        <p class="text-xl italic">
                        “Tucson is large enough to attract lots of major events and have opportunities for anything you want to do while still maintaining the feeling of a small town. It is also well situated around a beautiful natural landscape that it is easy to explore.”                        </p>
                        <br/>
                        <p class=" font-bold">—— Caroline, Science and Spanish Major</p>
                    </div>
                
                 </div>
                 </div>
            
            </div>
            </>
        }
         
        </>
      
    );
}

export default Info;