import React from "react";
import Footer from "../components/Footer";
import {Helmet} from 'react-helmet';
import Hero from "../components/Hero";
import LearnMore from "../components/LearnMore";
import Nav from "../components/Nav";
import Squares from "../components/Squares";
import heroImage from "../assets/images/life-at-arizona-hero.jpg"
import campus from "../assets/images/campus.jpg"
import tucson from "../assets/images/tucson.jpg"
import opportunities from "../assets/images/opportunities.jpg"
import Info from "../components/Info";

const Life = () => {
    return (
        <div>
              <Helmet>
        <meta charSet="utf-8" />
        <title>Life at Arizona | University of Arizona</title>
        <meta name="description" content="Fall in love with our beautiful campus and discover Tucson, your new favorite college town." />
      </Helmet>
            <Nav/>
            <Hero heroImage={heroImage}/>
            <Squares/>
            <LearnMore life={true} mainHeading={"DREAM BIG. BEAR DOWN."} secondaryHeading={"Unbeatable Campus"} bodyText="From the moment you set foot on campus, you’ll fall in love with its beautiful scenery and lively energy. Our passionate community celebrates individuals like you who are driven to make an impact. And with hundreds of student clubs and organizations, there is no shortage of ways to grow your network by getting involved. Get started by exploring our colleges and degree programs." learnMoreImage={campus}/>
            <Hero heading={"Exceptional Opportunities"} bannerImage={opportunities} text={"At Arizona, you will be empowered to combine your multiple interests to create a college experience that’s as unique as you are. For example, you may choose to get involved in a research project, perform an internship, get involved with one of our cultural centers, write for the university newspaper, play intramural sports … or all of the above."}/>
            <LearnMore life={true} secondaryHeading={"Tucson is a City That Inspires"} bodyText="A diverse melting pot in the Southwest, Tucson is buzzing with culture. Take a stroll along the eclectic University Boulevard or hop on the streetcar to visit the famous Fourth Avenue. Tucson is almost always a few degrees cooler than Phoenix, which is why it’s a dream for outdoor enthusiasts who enjoy year-round activities like hiking and cycling." learnMoreImage={tucson}/>
            <Info life={true}/>
            <Footer/>
        </div>
    );
}

export default Life;