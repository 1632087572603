import {Helmet} from 'react-helmet';
import Nav from '../components/Nav';
import '../App.scss';
import Hero from '../components/Hero';
import heroImage from '../assets/images/executive-privileges-hero.jpg';
import LearnMore from '../components/LearnMore';
import Squares from '../components/Squares';
import Footer from '../components/Footer';
import Perks from '../components/Perks';
import Gallery from '../components/Gallery';
import play from '../assets/images/play.jpg';
import work from '../assets/images/work.jpg';
import connect from '../assets/images/conect.jpg';


const Vip = () => {
  return (
    <div className="App">
       <Helmet>
        <meta charSet="utf-8" />
        <title>Flinn Exclusive Privileges | University of Arizona</title>
        <meta name="description" content="Be a VIP member of the W.A. Franke Honors College and enjoy exclusive benefits to enhance your world-class Arizona experience. Join our tight-knit community and use your ambition to make an immediate impact." />
      </Helmet>
      <Nav/>
      <Hero heroImage={heroImage}/>
      <Squares/>
      <LearnMore vip={true} secondaryHeading="THIS IS WHAT DREAMS ARE MADE OF"/>
      <Perks/>
      <Gallery vip={true} image1={connect} image2={work} image3={play}/>
      <Footer/>
    </div>
  );
}

export default Vip;
