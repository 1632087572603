import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider} from 'react-router-dom';
import './index.css';
import Home from './pages/Home';
import Scholars from './pages/Scholars';
import Vip from './pages/Vip'
import Profiles from './pages/Profiles';
import Life from './pages/Life';
import Finalist from './pages/Finalist';

const router = createBrowserRouter([
  {
    path:'*',
    element: <Home/>
  },
  {
    path:'/',
    element: <Home/>
  },
  {
    path: '/scholars',
    element: <Scholars/>
  },
  {
    path: '/vip',
    element: <Vip/>
  },
  {
    path: '/profiles',
    element: <Profiles/>
  },
  {
    path: '/life',
    element: <Life/>
  },
  {
    path: '/finalist',
    element: <Finalist/>
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);
