import React from "react";
import Square from '../assets/images/abstract-square-group.svg'
import Scholars from "../pages/Scholars";
import legacy from '../assets/images/your-legacy-starts.jpg'
import Button from './Button';
import john from '../assets/images/dr-pollard.jpg'
import signature from '../assets/images/signature_blue.png' 

const LearnMore = ({finalist, life, home, scholars, mainHeading,secondaryHeading, learnMoreImage, vip, profiles, bodyText, secondaryBodyText}) => {
    
    return (
        <div id="learn-more-hero">
            {
                home && 
                <div class="p-24-LR container flex flex-wrap max-lg:justify-center">
                <div class="lg:basis-7/12 sm:basis-full">
                <p>You’re looking for a university with the right combination of academic rigor and hands-on opportunities to achieve the future you’re dreaming of. As a member of the University of Arizona’s W.A. Franke Honors College, you’ll have it all. Here, you’ll use your talents to make an immediate impact as you work towards accomplishing your biggest, boldest goals. We’re home to some of the best and brightest minds from around the world; as a top-performing student, we know you’ll fit right in.</p>
            </div>
    
                <div class="">
                <Button text={"LEARN MORE"}/>
                </div>
                </div>
         
            }

            {
                scholars && 
                <div class="p-24-LR container md:pt-20 flex flex-wrap justify-between max-lg:justify-center max-lg:pl-2 max-lg:pr-2">
                    <div class="pb-8 lg:basis-1/2 max-[968px]:basis-full">
                        <p class="arizona-blue font-extrabold text-6xl max-sm:text-3xl">{mainHeading}</p>
                        <p class="arizona-blue font-bold text-4xl max-sm:text-3xl max-md:pt-5">{secondaryHeading}</p>
                        <br/>
                        <p>{bodyText} </p>
                        <br/>
                        <p>{secondaryBodyText}</p>
                    </div>
                    <div>
                        <img class="sm:max-w-[486px] " src={learnMoreImage}></img>
                    </div>
                </div>
            }

            {
                vip && 
                <div class="p-24-LR container pt-20 pb-20 flex flex-wrap justify-between max-lg:pl-2 max-lg:pr-2">
                    <div class="lg:basis-1/2 max-lg:text-center">
                        <p class="arizona-blue font-extrabold text-6xl max-sm:text-3xl">{secondaryHeading}</p>
                        <br/>
                        <p>As a high-performing student, you deserve a Flinn experience with serious perks. That’s exactly what you will discover at the University of Arizona W.A. Franke Honors College.</p>
                        <br/>
                    </div>
                    <div>
                        <img class="max-w-[486px] " src={learnMoreImage}></img>
                    </div>
                </div>
            }

            {
                profiles && 
                <div class="p-24-LR container md:pt-20 md:pb-20 flex flex-wrap justify-between">
                    <div class="lg:basis-1/2">
                        <p class="arizona-blue font-extrabold text-6xl max-sm:text-3xl">{secondaryHeading}</p>
                        <br/>
                        <p>{bodyText}</p>
                        <br/>
                    </div>
                    <div>
                        <img class="max-w-[486px] " src={learnMoreImage}></img>
                    </div>
                </div>
            }

            {
                life && 
                <div class="p-24-LR container md:pt-20 md:pb-20 ">
                <p class="font-extrabold text-6xl arizona-blue w-1/2 pr-24 max-sm:text-3xl">{mainHeading}</p>
                <br/>
                <div class="flex flex-wrap justify-between max-lg:justify-center">
                    <div class="lg:basis-1/2">
                        <p class="arizona-blue font-bold text-4xl pt-12 max-sm:text-3xl">{secondaryHeading}</p>
                        <br/>
                        <p>{bodyText}</p>
                        <br/>
                    </div>
                    <div>
                        <img class="sm:max-w-[486px] " src={learnMoreImage}></img>
                    </div>
                </div>
                </div>
            }
           

                  {
                finalist && 
                <div class="p-24-LR container flex flex-wrap max-lg:justify-center pb-10 message">
                    <div class="lg:basis-7/12 sm:basis-full">
                    <p>In recognition of your notable achievements, you are invited to a special event specifically for Flinn Finalists like yourself. This is your chance to tour our beautiful campus, meet Franke Honors staff and faculty mentors, network with Franke Flinn Scholars, and preview all of the opportunities our W.A. Franke Honors College has to offer.</p>
                    </div>
    
                    <div class="max-lg:mt-6">
                        <div class="margin-auto sm:ml-auto sm:mr-auto lg:ml-24 text-white pt-3 pb-3 pl-20 pr-20 font-extrabold text-center arizona-red-bg sm:w-72 rounded-3xl max-sm:pl-10 max-sm:pr-10 ">
                            <a target="_blank" href="https://slate.admissions.arizona.edu/register/?id=d5c9fcd0-ae56-4a05-80f4-588ceae34e6c">RSVP</a>
                        </div>
                        <div class="justify-between eventDetailsButton margin-auto sm:ml-auto sm:mr-auto lg:ml-24 mt-6 text-white pt-3 pb-3 pl-20 pr-20 font-extrabold text-center arizona-red-bg sm:w-72 rounded-3xl max-sm:pl-10 max-sm:pr-10 ">
                            <a href="#eventDetailsSection">Event Details</a>
                        </div>

                    </div>

                    <div class="mt-20 ">
                        <p class="arizona-blue text-4xl font-bold max-w-[588px]">A Message From Franke Honors Dean John Pollard</p>
                        <div class="sm:flex mt-8">
                            <div class="sm:mr-12 max-sm:max-w-[200px] mb-8">
                                <img src={john}/>
                            </div>
                            <div>
                                <p class="italic azurite">Congratulations on being named a Flinn Scholarship Finalist. You have distinguished yourself as an exceptional student. You will find that our W.A. Franke Honors College leverages the world-class research and academics of the University of Arizona to offer you an undergraduate education grounded in an innovative, interdisciplinary curriculum, wide-ranging research, and a strong community. </p> 
                                <p class="pt-4 italic azurite">I look forward to personally welcoming you to our campus very soon.</p>
                                <p class="pt-4 pb-4 italic azurite"> Sincerely,</p>
                                <div class="sm:flex">
                                    <div class="">
                                        <img class="max-w-[188px]" src={signature}/>
                                    </div>
                                    <div>
                                        <p>Dr. John Pollard </p>
                                        <p>Dean, W.A. Franke Honors College</p>
                                        <p>Professor of Practice, Chemistry & Biochemistry</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                }
        
        </div>
    );
};

export default LearnMore;