import React from "react";
import mentorship from '../assets/images/mentorship.svg';
import priority from '../assets/images/priority-registration.svg';
import play from '../assets/images/play.jpg';
import events from '../assets/images/community-events.svg';
import research from '../assets/images/research-opportunities.svg';
import classes from '../assets/images/smaller-classes.svg'

const Perks = () => {
    return (
        <div class="container">
            <div class="flex flex-wrap p-24-LR">
                <div class="pr-3 max-lg:text-center lg:basis-1/3">
                    <img class="max-lg:ml-auto max-lg:mr-auto" src={classes} alt="stylized 'A' plus icon"></img>
                    <p class="pt-4 pb-4 font-bold text-2xl">Smaller Classes</p>
                    <p>Honors classes are designed to be especially thought-provoking and engaging. With limited enrollment, you’ll learn in a personal, enriching environment led by some of campus’ top faculty.</p>
                </div>
                <div class="pr-3 max-lg:text-center lg:basis-1/3">
                    <img class="pt-2 max-lg:pt-5 max-lg:ml-auto max-lg:mr-auto" src={research} alt="stylized book icon"></img>
                    <p class="pt-8 pb-4 font-bold text-2xl">Research Opportunities</p>
                    <p>As soon as your first year, you can work with nationally recognized professors on important research projects in almost any academic area. Arizona is a top-20 research institution and the experiences you have here will benefit you for the rest of your life.</p>
                </div>
                <div class="max-lg:text-center lg:basis-1/3">
                    <img class="max-lg:pt-5 max-lg:ml-auto max-lg:mr-auto"src={mentorship} alt="stylized two hands shaking icon"></img>
                    <p class="pt-4 pb-4 font-bold text-2xl">Mentorship</p>
                    <p>Each Franke Flinn Scholar is paired with a faculty mentor. Your mentor will guide you, challenge you and connect you with unbeatable opportunities to grow personally and professionally.</p>
                </div>
            </div>
            <div class="flex flex-wrap pt-14 pb-20 p-24-LR max-lg:pt-5">
                <div class="pr-3 max-lg:text-center lg:basis-1/3">
                    <img class="max-lg:pt-5 max-lg:ml-auto max-lg:mr-auto" src={priority} alt="stylized clipboard with checklist icon"></img>
                    <p class="pt-4 pb-4 font-bold text-2xl">Priority Registration</p>
                    <p>You’ll be first in line to get the classes you want. During each year of your undergraduate education, you’ll be able to register for classes before others.</p>
                </div>
                <div class="pr-3 max-lg:text-center lg:basis-1/3">
                    <img class="max-lg:pt-5 max-lg:ml-auto max-lg:mr-auto" src={events} alt="stylized silhouettes of three people icon"></img>
                    <p class="pt-4 pb-4 font-bold text-2xl">Community Events</p>
                    <p>As a Franke Flinn Wildcat, you’ll grow your personal and professional network via a variety of community events designed to help you build lasting connections with your peers and mentors.</p>
                </div>
            </div>
        </div>
    );
}

export default Perks;