import React, {useState} from "react";
import { Link } from "react-router-dom";
import Logo from '../assets/images/Franke-Honors-Logo.svg'
import wordmark from '../assets/images/university-wordmark.svg'


const Nav = ({id}) => {

	const [active, setActive] = useState(false);
	
	const handleClick = () => {
		setActive(!active);
	}

	const path = window.location.pathname;

    return (
        <div>
			<header>
			
			<div class="header" id="the-university-of-arizona-header">
				<div class="header-bar arizona-red-bg pad24LR">
					<div class="container">
						<div class="text-logo">
							<a href="http://www.arizona.edu" target="_blank" rel="noopener"><img src={wordmark} alt="The University of Arizona logo"/></a>
						</div>
					</div>
				</div>

			</div>
		</header>
                <div class="nav-container arizona-blue-bg pad24" id={id}>
					<div class="container flex justify-between flex-wrap">
						<div class="logo">
							<a class="nav-link" href="https://frankehonors.arizona.edu/"><img alt="W.A Franke Honors College | Home " src={Logo} id="honors-college-header" /></a>
						</div>
						<button class={`burger ${active ? 'change' : 'closed'}`} onClick={handleClick}>
							<div class="bar1"></div>
							<div class="bar2"></div>
							<div class="bar3"></div>
						</button>
						<div id="nav" class="nav">
							<nav>
                                <ul class="flex lg:pt-0 justify-end arizona-light-blue pb-5">
                                    <li class="relative">
										<a class="pr-5 triangle" id="wa-franke-honors-college-nav" href="https://frankehonors.arizona.edu/?_gl=1*gia8i*_ga*OTQ3OTI5NjAxLjE2NjMwMjAzOTA.*_ga_7PV3540XS3*MTcwMDUyMzI3NC4xNjkuMS4xNzAwNTI0MzM1LjQzLjAuMA.."><span class="link-text ">WA Franke Honors College</span></a>
									</li>
									<li class="relative triangle"><a class="pr-5" id="why-honors-nav" href="https://frankehonors.arizona.edu/why-honors/honors-community?_gl=1*gia8i*_ga*OTQ3OTI5NjAxLjE2NjMwMjAzOTA.*_ga_7PV3540XS3*MTcwMDUyMzI3NC4xNjkuMS4xNzAwNTI0MzM1LjQzLjAuMA.."><span class="link-text">Why Honors</span></a></li>
									<li class="relative last"><a class="pr-2"  id="visit-nav" href="https://frankehonors.arizona.edu/admissions/visit-us?_gl=1*gia8i*_ga*OTQ3OTI5NjAxLjE2NjMwMjAzOTA.*_ga_7PV3540XS3*MTcwMDUyMzI3NC4xNjkuMS4xNzAwNTI0MzM1LjQzLjAuMA.."><span class="link-text">Visit</span></a></li>
                                </ul>
								<ul class="flex text-white">
									<li><Link id="flinn-home-nav" class="pr-2 azurite-border-r " to="/"><span class={`link-text ${path == '/' ? 'active' : 'inactive'} `}>Home</span></Link></li>
									<li><Link id="flinn-scholars-nav" class="pl-2 pr-2 azurite-border-r " to="/scholars"><span class={`link-text ${path == '/scholars' ? 'active' : 'inactive'} `}>Flinn Scholars</span></Link></li>
									<li><Link id="exclusive-privileges-nav" class="pl-2 pr-2 azurite-border-r" to="/vip"><span class={`link-text ${path == '/vip' ? 'active' : 'inactive'} `}>Exclusive Privileges</span></Link></li>
									<li><Link id="meet-current-flinns-nav" class="pl-2 pr-2 azurite-border-r" to="/profiles"><span class={`link-text ${path == '/profiles' ? 'active' : 'inactive'} `}>Meet Franke Flinns</span></Link></li>
									<li><Link id="life-at-arizona-nav" class="pl-2" to="/life"><span class={`link-text ${path == '/life' ? 'active' : 'inactive'} `}>Life At Arizona</span></Link></li>
								</ul>
							</nav>
						</div>

					</div>
					<div className= {`nav-col ${active ? 'open' : 'closed'}`}>
						<div>
							<ul class="flex lg:pt-0 justify-end arizona-light-blue pb-2 flex-col">
									<li class="pl-2 pb-3 pt-8 text-white"><Link id="flinn-home-nav" to="/">Home</Link></li>
									<li class="pl-2 pb-3 text-white"><Link id="flinn-scholars-nav" to="/scholars">Flinn Scholars</Link></li>
									<li class="pl-2 pb-3 text-white"><Link id="exclusive-privileges-nav" to="/vip">Exclusive Privileges</Link></li>
									<li class="pl-2 pb-3 text-white"><Link id="meet-current-flinns-nav" to="/profiles">Meet Franke Flinns</Link></li>
									<li class="pl-2 pb-3 text-white"><Link id="life-at-arizona-nav" to="/life">Life At Arizona</Link></li>
                                    <li class="pl-2 pb-3"><a id="wa-franke-honors-college-nav" href="https://frankehonors.arizona.edu/?_gl=1*gia8i*_ga*OTQ3OTI5NjAxLjE2NjMwMjAzOTA.*_ga_7PV3540XS3*MTcwMDUyMzI3NC4xNjkuMS4xNzAwNTI0MzM1LjQzLjAuMA.">WA Franke Honors College</a></li>
									<li class="pl-2 pb-3"><a id="why-honors-nav" href="https://frankehonors.arizona.edu/why-honors/honors-community?_gl=1*gia8i*_ga*OTQ3OTI5NjAxLjE2NjMwMjAzOTA.*_ga_7PV3540XS3*MTcwMDUyMzI3NC4xNjkuMS4xNzAwNTI0MzM1LjQzLjAuMA..">Why Honors</a></li>
									<li class="pl-2"><a id="visit-nav" href="https://frankehonors.arizona.edu/admissions/visit-us?_gl=1*gia8i*_ga*OTQ3OTI5NjAxLjE2NjMwMjAzOTA.*_ga_7PV3540XS3*MTcwMDUyMzI3NC4xNjkuMS4xNzAwNTI0MzM1LjQzLjAuMA..">Visit</a></li>
							</ul>
						</div>
								
						</div>
				</div>
        </div>
    )
}

export default Nav;